.App-body{
  margin-top:5%;
  margin-bottom: 5%;
  width : 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
}

.App {
  border: 1px solid #646262;
  border-radius: 5px;
}

.grid {
  display: grid;
  grid-template-columns: 30% 40% 20%;
}

.animation {
  grid-column: 3;
  text-align: left;
  width: 150%;
  margin-top: -10%;
}

.terminal {
  grid-column: 2;
  text-align: left;
}

.aboutMe {
  grid-column: 1;
  display: flex;
  flex-direction: column; /* Display items vertically */
  justify-content: flex-start; /* Align items at the start */
  align-items: flex-start; /* Align items to the left */
  padding: 20px; /* Add padding for neat appearance */
}

.aboutMe > * {
  margin-bottom: 10px; /* Add margin between items */
}

.justify{
  text-align: justify;
}

.padding{
  padding-right:10px ;
}

@media screen and (max-width: 1470px) {
  .grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aboutMe,
  .terminal,
  .animation {
    width: 100%;
    max-width: 700px; /* Adjust this value as needed */
  }
  .animation{
    grid-row-start: 2;
  }
}

@media screen and (max-width: 573px) {
  .grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aboutMe,
  .terminal,
  .animation {
    width: 100%;
    max-width: 300px; /* Adjust this value as needed */
  }

  .animation{
    grid-row-start: 2;
  }

  img {
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    height: auto; /* Maintain aspect ratio */
  }
}