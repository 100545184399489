.macos-terminal-window {
    position: relative;
    background-color: #2d2d2d;
    color: #fff;
    font-family: 'Menlo', monospace;
    border: 1px solid #333;
    border-radius: 6px;
    width: 98%;
    height: 600px !important;
    overflow-y: auto;
}

.macos-terminal-window::-webkit-scrollbar {
    width: 0;  
    height: 0; 
    background: transparent; 
}

.macos-terminal-header {
    position: sticky;
    background-color: #646262;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    top : 0;
}
.scrollable-content {
    padding-top : 1%;
}

.button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 5px;
}

.close {
    background-color: #ff5f56; /* Red */
}

.minimize {
    background-color: #ffbd2e; /* Yellow */
}

.maximize {
    background-color: #27c93f; /* Green */
}

.macos-terminal-body {
    padding: 10px;
}

.project {
    font-family: 'Menlo', monospace;
    color: #fff;
}

.project ul {
    list-style-type: none;
}

.project li {
    padding: 5px 0;
}

.education {
    font-family: 'Menlo', monospace;
    color: #fff;
}

.work-experience {
    font-family: 'Menlo', monospace;
    color: #fff;
}

.skills {
    font-family: 'Menlo', monospace;
    color: #fff;
}

.skills ul {
    list-style-type: none;
}

.skills li {
    padding: 5px 0;
}

.publications {
    font-family: 'Menlo', monospace;
    color: #fff;
}

.achievements {
    font-family: 'Menlo', monospace;
    color: #fff;
}

.terminal-line {
    font-family: 'Courier New', Courier, monospace;
    color: #839496; 
    padding: 5px;
  }
  
  .username {
    color: #859900; 
  }
  
  .heading {
    color: #268bd2; 
  }

  .centered-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    flex-direction: column;
    height: 100%; 
  }

  .centered-container > * {
    display: block; 
    margin: 0% 1%; 
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Menlo', monospace;
    font-size: 16px; 
    color : #00b5ae;
}

/* Normal Text Font Size */
p, span, li {
    font-family: 'Menlo', monospace;
    font-size: 12px; 
}
.center {
    margin-left : 23%;
}

strong {
    color: #FFCB6B;
}